import React, { useState, useEffect } from "react";
import {
  moveToFirstStep,
  moveBackword,
  moveForward,
  moveToLastStep,
} from "../../shared-functionalities";

import {BackStep, ForwardStep, SkipFirst, SkipLast, Play, Pause} from "../svg";
let intervalId;

export default function AnimateSteps(props) {
  const {
    tsOptions,
    setTSOptions,
    currentStep,
    setCurrentStep,
    meshesForStep,
    setPreveStep,
    maxStepsNumber, width,
    preveStep,
    divided
  } = props;

  const [skipFirst, setSkipFirst] = useState(false);
  const [skipLast, setSkipLast] = useState(false);
  const [backStep, setBackStep] = useState(false);
  const [forwardStep, setForwardStep] = useState(false);

const animateShortcuts = (e) => {
  let isAnimateStart = !tsOptions.isAnimateStart;
  if (e.key === ' ') {
    if (isAnimateStart) {
      animateStep();
    } else {
      clearInterval(intervalId);
      setPreveStep(currentStep)
    }
    setTSOptions((prevOptions) => ({
      ...prevOptions,
      isAnimateStart: isAnimateStart,
  }))
  }
  if (e.key === 'ArrowRight') {  
    if (tsOptions.loading || tsOptions.isTsPrepared || currentStep === allSteps) return;
    moveForward(currentStep, setCurrentStep, meshesForStep.length - 1, setPreveStep)
  }
  if (e.key === 'ArrowLeft') {
    if (tsOptions.loading || tsOptions.isTsPrepared || currentStep === 0) return;
    moveBackword(currentStep, setCurrentStep, meshesForStep.length - 1, setPreveStep)
  }
  if (e.key === 'ArrowDown') {
    if (tsOptions.loading || tsOptions.isTsPrepared) return;
    moveToFirstStep(currentStep, setCurrentStep, setPreveStep)
  }
  if (e.key === 'ArrowUp') {
    if (tsOptions.loading || tsOptions.isTsPrepared) return;
    moveToLastStep(currentStep, meshesForStep.length - 1, setCurrentStep, setPreveStep)
  }
}

  useEffect(() => {
    if (tsOptions.isSplit) {
      clearInterval(intervalId);
      setPreveStep(currentStep)
    }
  }, [tsOptions.isSplit])
  useEffect(() => {
    window.addEventListener("keydown", animateShortcuts)
    return () => {
      window.removeEventListener("keydown", animateShortcuts)
    }
  }, [currentStep, tsOptions.isAnimateStart, tsOptions.loading, tsOptions.isTsPrepared])

  let allSteps = meshesForStep.length - 1
  let animateStep = () => {
    let num = currentStep === meshesForStep.length - 1 ? 0 : currentStep;
    let previousStep = 0;
    intervalId = setInterval(() => {
      if (num === meshesForStep.length - 1) {
        setTSOptions((prevOptions) => ({
          ...prevOptions,
          isAnimateStart: !prevOptions.isAnimateStart,
        }));
        setPreveStep(meshesForStep.length - 1)
        num = 0;
        clearInterval(intervalId);
        return;
      }
      if (num === 0) {
        setCurrentStep((prevStep) => {
          previousStep = prevStep;
          return num;
        });
      }
      setCurrentStep((pervStep) => {
        if (pervStep !== num) {
          // console.log("chcek nummmmm ", pervStep, num);
          setTSOptions((prevOptions) => ({
            ...prevOptions,
            isAnimateStart: !prevOptions.isAnimateStart,
          }));
          clearInterval(intervalId);
          return pervStep;
        }
        num += previousStep === meshesForStep.length - 1 ? 0 : 1;
        previousStep = num === 0 ? num : num - 1;
        return num;
      });
    }, tsOptions.STEPS_PAUSE || 500);
    
  };

  return (
    !tsOptions.isSplit && <div
      className={`bounty_play_pause_container ${maxStepsNumber > 45 && (width <= 1920 && width > 839 || divided) ?  "bounty_play_pause_container_big_steps": ""}`}
      style={
        tsOptions.loading ||
        tsOptions.isTsPrepared ||
        !tsOptions.isTSViewerFound
          ? { zIndex: "-1" }
          : maxStepsNumber > 45 && width <= 1920 && width > 839 ? divided ? {zIndex: "1"}: {zIndex: "1", left: '45%'} : {zIndex: "1", left: '20%'}
      }
    >
      <div className="bounty_steps_controls"
        onClick={() => {
            if (tsOptions.loading || tsOptions.isTsPrepared) return;
            moveToFirstStep(currentStep, setCurrentStep, setPreveStep);
        }}
        onMouseDown={() => {
          setSkipFirst(true)
        }}
        onMouseUp={() => {
          setSkipFirst(false)
        }}>
        <SkipFirst />
      </div>
      <div className="bounty_steps_controls"
      onClick={() => {
          if (tsOptions.loading || tsOptions.isTsPrepared || currentStep === 0) return;
          moveBackword(currentStep, setCurrentStep, meshesForStep.length - 1, setPreveStep);
      }}
      onMouseDown={() => {
        setBackStep(true)
      }}
      onMouseUp={() => {
        setBackStep(false)
      }}>
  <BackStep />
      </div>
      <div className="bounty_steps_controls"
      onClick={ () =>{ if (tsOptions.loading || tsOptions.isTsPrepared) return;
        let isAnimateStart = !tsOptions.isAnimateStart;
        if (isAnimateStart) {
          animateStep();
        } else {
          clearInterval(intervalId);
          setPreveStep(currentStep)
        }
        setTSOptions((prevOptions) => ({
          ...prevOptions,
          isAnimateStart: isAnimateStart,
      }));}}>
        {tsOptions.isAnimateStart ? <Pause /> :<Play />} 
      </div>
      <div className="bounty_steps_controls"
      onClick={() => {
          if (tsOptions.loading || tsOptions.isTsPrepared || currentStep === allSteps) return;
          moveForward(currentStep, setCurrentStep, meshesForStep.length - 1, setPreveStep);
      }}
      onMouseDown={() => {
        setForwardStep(true)
      }}
      onMouseUp={() => {
        setForwardStep(false)
      }}>
      <ForwardStep />
      </div>
      <div className="bounty_steps_controls"
      onClick={() => {
        if (tsOptions.loading || tsOptions.isTsPrepared) return;
        moveToLastStep(currentStep, meshesForStep.length - 1, setCurrentStep, setPreveStep);
      }}
    onMouseDown={() => {
      setSkipLast(true)
    }}
    onMouseUp={() => {
      setSkipLast(false)
    }}>
      <SkipLast />
      </div>
    </div>
  );
}
